<template>
  <SingleModelLayout
    :model="model"
    :route-back="{
      name: 'assemblies.index'
    }"
    @refresh-model="getModel"
  >
    <template #title>
      <TitleSummaryCard
        class="mb-4"
        :edit.sync="edit"
        @edit="startEdit"
        @save="saveModel"
        @discard="discardEdit"
      >
        <template #title>
          <vue-data
            v-model="model.name"
            :value="model.name"
            :edit="edit"
            :border="false"
          />
        </template>
        <template #subtitle class="opacity-50">
          {{ model.id }}
        </template>
      </TitleSummaryCard>
    </template>
    <template #left>
      <vue-data
        v-model="model.key"
        :value="model.key"
        :edit="edit"
        class="mb-2"
        label="Key"
      />
      <vue-data
        v-model="model.icon"
        :value="model.icon"
        :edit="edit"
        class="mb-2"
        label="Icon Name"
      />
      <vue-data
        v-model="model.required"
        :value="model.required"
        :edit="edit"
        type="checkbox"
        class="mb-2"
        label="Required?"
      />
    </template>
    <template #main>
      <LocaleSwitcher
        :edit="edit"
        @switch="getModel"
      />
    </template>
  </SingleModelLayout>
</template>
<script type="text/javascript">
import { cloneDeep } from 'lodash'
import SingleModelLayout from '@/components/layouts/SingleModelLayout'
import TitleSummaryCard from '@/components/TitleSummaryCard'
import LocaleSwitcher from '@/components/LocaleSwitcher'

export default {
  name: 'SetsSingle',

  components: {
    SingleModelLayout,
    TitleSummaryCard,
    LocaleSwitcher
  },

  data () {
    return {
      loading: false,
      model: false,
      modelOld: false,
      files: false,
      edit: false
    }
  },

  created () {
    this.getModel()
  },

  methods: {
    getModel () {
      this.loading = true
      this.$api.get(`assemblies/${this.$route.params.model}`, {
        params: {
          locale: this.$route.params.locale,
          withImages: true,
          withPivot: true
        }
      })
        .then((res) => {
          this.model = res.data
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    saveModel () {
      this.loading = true
      this.$api.put(`assemblies/${this.$route.params.model}`, {
        ...this.model,
        locale: this.$route.params.locale
      })
        .then((res) => {
          this.getModel()
          this.edit = false
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    startEdit () {
      this.modelOld = cloneDeep(this.model)
    },

    discardEdit () {
      this.model = this.modelOld
      this.modelOld = false
      this.edit = false
    }
  }
}
</script>
